import {
  Card as MUIcard,
  // CardContent,
  // CardMedia,
  // CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
// import { useTheme } from '@material-ui/core/styles'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, {
  forwardRef,
  // useEffect,
  useImperativeHandle,
  useRef,
  // useState,
} from 'react'
import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from 'react-dnd'
import closeIcon from '../../../assets/images/close.svg'
import { stylesGrid } from './stylesGrid'
import { styles } from './styles'
import IgImageView from '../../IgImageView'
import { navigate } from 'gatsby'
interface ICardProps {
  folder: any
  index: number
  id: any
  moveCard: any
  isDragging: boolean
  connectDragSource: any
  connectDropTarget: any
  elGrid: any
  canEdit: boolean
  handleUpdatePosition: Function
  onRemove: Function
  userName: any
}

const Card = forwardRef(
  (
    {
      folder,
      isDragging,
      connectDragSource,
      connectDropTarget,
      canEdit,
      onRemove,
      userName,
      handleUpdatePosition,
    }: ICardProps,
    ref: any
  ) => {
    const classesGrid = stylesGrid()
    const classes = styles()
    const elementRef = useRef(null)

    connectDragSource(elementRef)
    connectDropTarget(elementRef)

    const opacity = isDragging ? 0 : 1

    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }))

    return (
      <Grid
        item
        lg={3}
        md={4}
        sm={6}
        xs={6}
        ref={elementRef}
        style={{
          cursor: canEdit ? 'move' : 'pointer',
          opacity,
          userSelect: 'none',
          paddingTop: 0,
          paddingBottom: 40,
        }}
        className={classesGrid.SBCard}
        onClick={() =>
          navigate(
            `/s/${userName}/${folder?.name.replace(/\s+|\/|\\|#/g, '-')}/${
              folder.folderId
            }`
          )
        }
      >
        <MUIcard className={classesGrid.card} style={{ position: 'relative' }}>
          <div className={classes.imageWrapper}>
            {[0, 1, 2].map(index =>
              folder.items[index] ? (
                folder.items[index].type === 'instagram' ? (
                  <IgImageView
                    url={folder.items[index].data.link}
                    className={classes.image}
                  />
                ) : (
                  <img
                    src={
                      folder.items[index].data.imageUrl ||
                      folder.items[index].data.base64
                    }
                    alt={'product image 2 ' + index}
                    className={classes.image}
                  />
                )
              ) : (
                <div
                  className={classes.image}
                  style={{ backgroundColor: '#F5F6F7' }}
                />
              )
            )}
          </div>
          <Typography
            variant="h2"
            className={`${classes.edgeSpaces} ${classes.folderName}`}
          >
            {folder.name}
          </Typography>
          {canEdit && (
            <span
              className={classes.deleteButton}
              onClick={e => onRemove(e, { folderId: folder.folderId, name })}
            >
              {closeIcon && (
                <img
                  src={closeIcon}
                  className={classes.deleteIcon}
                  alt="buttonIcon"
                />
              )}
            </span>
          )}
        </MUIcard>
      </Grid>
    )
  }
)

interface CardProps {
  id: any
  index: number
  moveCard: any
  folder: any
  isDragging: boolean
  connectDragSource: ConnectDragSource
  connectDropTarget: ConnectDropTarget
  handleUpdatePosition: any
  canEdit: boolean
}

interface CardInstance {
  getNode(): HTMLDivElement | null
}

interface CardDragObject {
  id: string
  index: number
}

const scrollWhileDrag = (monitor: DragSourceMonitor) => {
  const p: any = monitor.getClientOffset().y
  const top25 = window.innerHeight / 4
  const bottom25 = (window.innerHeight / 4) * 3
  const speed = 7
  if (p <= top25) {
    window.scroll({
      top: Math.max(200, window.scrollY - speed),
    })
  } else if (p >= bottom25) {
    window.scroll({
      top: window.scrollY + speed,
    })
  }
}

const DraggableItem = DropTarget(
  'card',
  {
    hover(
      props: CardProps,
      monitor: DropTargetMonitor,
      component: CardInstance
    ) {
      const dragIndex = monitor.getItem<CardDragObject>().index
      const hoverIndex = props.index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return

      if (!monitor.isOver({ shallow: true })) return

      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex)

      monitor.getItem<CardDragObject>().index = hoverIndex
    },
    drop: ({ id, folder, index, handleUpdatePosition }: CardProps) => {
      handleUpdatePosition(id, folder.folderPosition, index + 1)
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    'card',
    {
      beginDrag: (props: CardProps, monitor: DragSourceMonitor) => {
        //@ts-ignore
        monitor.scrollTimer = setInterval(() => scrollWhileDrag(monitor), 1)
        return {
          id: props.id,
          index: props.index,
          props: props,
        }
      },
      endDrag: (props: CardProps, monitor: DragSourceMonitor) => {
        //@ts-ignore
        clearInterval(monitor.scrollTimer)
      },
      canDrag: (props: CardProps) => props.canEdit,
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(Card)
)

export default DraggableItem
